import React from "react"
import Contact from "../components/contact"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from "../styles/contactme.module.scss"

const Contactme = () => {
  return (
    <Layout>
      <Seo title="Kapcsolatfelvétel" />
      <section id={styles.container}>
        <header id={styles.header}>
          <h1>Lépj kapcsolatba velem</h1>
        </header>
        <div id={styles.contactInfo}>
          <div>
            Ha szeretnéd felvenni velem a kapcsolatot, akkor ezeken az
            elérhetőségeken kereshetsz, vagy küldj nekem e-mailt az alábbi
            fomanyomtatvány kitöltésével.
          </div>
          <ul>
            <li>NETALON Rendszerek Kft.</li>
            <li>Adószám: 12824483-1-13</li>
            <li>Cím: 2053 Herceghalom, Búzavirág utca 22.</li>
            <li>Kapcsolat: Kovács Gabriella</li>
            <li>Telefon: +36-20-9822-266</li>
            <li>email: kovacsg76@gmail.com</li>
          </ul>
        </div>
      </section>
      <Contact />
    </Layout>
  )
}

export default Contactme
